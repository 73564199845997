export const PredictionsPerPageSelect = () => import('../../src/components/predictions/PerPageSelect.vue' /* webpackChunkName: "components/predictions-per-page-select" */).then(c => wrapFunctional(c.default || c))
export const PredictionsTable = () => import('../../src/components/predictions/PredictionsTable.vue' /* webpackChunkName: "components/predictions-table" */).then(c => wrapFunctional(c.default || c))
export const ModalsBeforeLeaveModal = () => import('../../src/components/modals/BeforeLeaveModal.vue' /* webpackChunkName: "components/modals-before-leave-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsFeedbackModal = () => import('../../src/components/modals/FeedbackModal.vue' /* webpackChunkName: "components/modals-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOptimizeExperienceModal = () => import('../../src/components/modals/OptimizeExperienceModal.vue' /* webpackChunkName: "components/modals-optimize-experience-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSignUpModal = () => import('../../src/components/modals/SignUpModal.vue' /* webpackChunkName: "components/modals-sign-up-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseLayout = () => import('../../src/components/core/BaseLayout.vue' /* webpackChunkName: "components/core-base-layout" */).then(c => wrapFunctional(c.default || c))
export const CoreBillingButton = () => import('../../src/components/core/BillingButton.vue' /* webpackChunkName: "components/core-billing-button" */).then(c => wrapFunctional(c.default || c))
export const CoreFaqsSection = () => import('../../src/components/core/FaqsSection.vue' /* webpackChunkName: "components/core-faqs-section" */).then(c => wrapFunctional(c.default || c))
export const CoreToolImageLayout = () => import('../../src/components/core/ToolImageLayout.vue' /* webpackChunkName: "components/core-tool-image-layout" */).then(c => wrapFunctional(c.default || c))
export const CoreToolLayout = () => import('../../src/components/core/ToolLayout.vue' /* webpackChunkName: "components/core-tool-layout" */).then(c => wrapFunctional(c.default || c))
export const ToolsDemoSourceImages = () => import('../../src/components/tools/DemoSourceImages.vue' /* webpackChunkName: "components/tools-demo-source-images" */).then(c => wrapFunctional(c.default || c))
export const ToolsGenerateButton = () => import('../../src/components/tools/GenerateButton.vue' /* webpackChunkName: "components/tools-generate-button" */).then(c => wrapFunctional(c.default || c))
export const ToolsNegativePromptTextarea = () => import('../../src/components/tools/NegativePromptTextarea.vue' /* webpackChunkName: "components/tools-negative-prompt-textarea" */).then(c => wrapFunctional(c.default || c))
export const ToolsPastUploadImages = () => import('../../src/components/tools/PastUploadImages.vue' /* webpackChunkName: "components/tools-past-upload-images" */).then(c => wrapFunctional(c.default || c))
export const ToolsPromptTextarea = () => import('../../src/components/tools/PromptTextarea.vue' /* webpackChunkName: "components/tools-prompt-textarea" */).then(c => wrapFunctional(c.default || c))
export const ToolsSelectButtons = () => import('../../src/components/tools/SelectButtons.vue' /* webpackChunkName: "components/tools-select-buttons" */).then(c => wrapFunctional(c.default || c))
export const ToolsSelectImage = () => import('../../src/components/tools/SelectImage.vue' /* webpackChunkName: "components/tools-select-image" */).then(c => wrapFunctional(c.default || c))
export const ToolsSourceImage = () => import('../../src/components/tools/SourceImage.vue' /* webpackChunkName: "components/tools-source-image" */).then(c => wrapFunctional(c.default || c))
export const CoreNavStudioAiDropdown = () => import('../../src/components/core/nav/StudioAiDropdown.vue' /* webpackChunkName: "components/core-nav-studio-ai-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoreNavTheNavBar = () => import('../../src/components/core/nav/TheNavBar.vue' /* webpackChunkName: "components/core-nav-the-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const CoreNavTheNavBarMobile = () => import('../../src/components/core/nav/TheNavBarMobile.vue' /* webpackChunkName: "components/core-nav-the-nav-bar-mobile" */).then(c => wrapFunctional(c.default || c))
export const CoreNavTheSidebarMenuMobile = () => import('../../src/components/core/nav/TheSidebarMenuMobile.vue' /* webpackChunkName: "components/core-nav-the-sidebar-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const CoreNavUserNavDropdown = () => import('../../src/components/core/nav/UserNavDropdown.vue' /* webpackChunkName: "components/core-nav-user-nav-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoreNavUserNavMobile = () => import('../../src/components/core/nav/UserNavMobile.vue' /* webpackChunkName: "components/core-nav-user-nav-mobile" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseFlashNotifications = () => import('../../src/components/core/notifications/BaseFlashNotifications.vue' /* webpackChunkName: "components/core-notifications-base-flash-notifications" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseToastNotificationItem = () => import('../../src/components/core/notifications/BaseToastNotificationItem.vue' /* webpackChunkName: "components/core-notifications-base-toast-notification-item" */).then(c => wrapFunctional(c.default || c))
export const CoreNotificationsBaseToastNotifications = () => import('../../src/components/core/notifications/BaseToastNotifications.vue' /* webpackChunkName: "components/core-notifications-base-toast-notifications" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
